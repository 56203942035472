import React, { useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import './App.scss';
import Logo from './logo.png'

const schema = yup.object().shape({
  name: yup.string().matches(/^[a-zA-Z\s]*$/, 'Invalid name. Please enter a valid name.').required('Name is required.'),
  email: yup.string().matches(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, 'Invalid email. Please enter a valid email.').required('Email is required.'),
});

function App() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      await schema.validate({ name, email });
      // If the validation is successful, clear the message
      setMessage('');
  
      axios.post('http://localhost:7373/api/email', {
        name: name,
        email: email
      })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          // Set a success message
          setMessage(`Welcome to the BookDrop, ${name}! You've subscribed. 📚`);
        } else if (res.status === 400) {
          // Email already submitted
          // setMessage(res.data)
          setMessage('Email already submitted');
        } else {
          // Set an error message
          setMessage('An error occurred. Please try again.');
          // setMessage('An error occurred. Please try again.');
        }
      })
      .catch(err => {
        console.log(err);
        // Set an error message
        setMessage("You've already joined the waitlist. ✨");
      });
  
    } catch (err) {
      // If validation fails, set the error message
      setMessage(err.message);
    }
  };  

  return (
    <div className="App">
      <header className="App-header">
        <div className="main">
          <img
            src={Logo}
            alt="BookDrop logo"
            id="topLogo"
          />
          <h1>You've come to the right place.</h1>
          <p>Get ready for weekly book drops.</p>
        </div>

        <form onSubmit={handleSubmit}>
          <p>Be notifed. 📧</p>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Your name"
            required
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Your email"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <input
            type="submit"
            value="Join the waitlist"
            id="submit"
          />
          <p id="message">{message}</p> {/* Message will appear here */}
        </form>
      </header>
    </div>
  );
}

export default App;